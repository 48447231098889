@import '../colors';
@import '../fonts';
@import '../variables';

.landing {
  .motion-hero {
    display: flex;
    height: 90%;
    justify-content: right;
    overflow: hidden;
    position: relative;

    &-wrapper {
      height: 80vh;
      position: relative;
    }

    img {
      margin-left: $side-gap;
      min-width: 90vw;
      overflow: hidden;
      position: absolute;
    }
  }
}

@media screen and (max-width: $desktop) {
  .landing {
    .motion-hero {
      img {
        margin-left: $side-gap-half;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .landing {
    .motion-hero {
      height: 70vh;

      &-wrapper {
        height: 100%;
      }

      img {
        min-width: unset;
        margin-left: 0;
        width: 200%;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .landing {
    .motion-hero {
      height: 40vh;

      img {
        margin-left: 0;
        width: 150%;
      }
    }
  }
}
