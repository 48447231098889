$dark-grey: #3d3d3d;
$text-grey: #6d6d6d;
$mti-grey: #f0f0f0;
$growth-grey: #f3f2f2;
$sub-page-grey: #f7f7f7;
$chart-grey: #d8d8d8;
$press-border: #cecece;
$white: #fff;
$off-white: #eee;
$black: #000;
$marine: #19444b;
$light-marine: #266872;
$dark-marine: #103e45;
$heading-marine: #053d45;
$light-grey: #aaa;
$orange: #de592e;
$faded-orange: rgba(222, 89, 46, .4);
$footer-bg: #1a2127;
$sub-page-black: #292929;
$transparent: rgba(0, 0, 0, 0);
