@import '../colors';
@import '../fonts';
@import '../variables';

.contact {
  &-body {
    background: $mti-grey;
    display: flex;
    padding: 60px $side-gap;

    &-wrapper {
      background: $mti-grey;
    }
  }

  &-form {
    margin-right: $side-gap;
    width: 60%;

    &-title {
      color: $marine;
      font-family: $open-sans-reg;
      font-size: 36px;
    }

    &-errors {
      color: $orange;
      font-family: $open-sans-reg;
      margin-bottom: 16px;
    }

    &-button {
      border: 1px solid $light-grey;
      color: $light-grey;
      font-family: $open-sans-bold;
      font-size: 18px;
      margin-top: 16px;
      padding: 12px 0;
      width: 25%;

      &:focus {
        outline: none;
      }

      &-wrapper {
        display: flex;
        justify-content: flex-end;
      }
    }

    form {
      margin-top: 40px;

      input {
        background-color: $white !important;
        border: 1px solid $white;
        color: $text-grey;
        font-size: 16px;
        margin-bottom: 16px;
        padding: 16px 12px;
        width: 100%;

        &:focus {
          // border: 0;
          border-bottom: 1px solid $orange;
          outline: 0;
        }

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 30px $white inset !important;
          appearance: none;
          background-color: $white !important;
          color: $text-grey !important;
        }
      }
    }
  }

  &-thanks {
    color: $text-grey;
    font-family: $open-sans-reg;
    font-size: 18px;
    margin-top: 42px;

    &-you {
      font-family: $open-sans-bold;
      font-size: 24px;
    }
  }
}

@media screen and (max-width: $desktop) {
  .contact {
    &-body {
      padding: 60px $side-gap-half;
    }

    &-form {
      margin-right: $side-gap-half;
    }
  }
}

@media screen and (max-width: $tablet) {
  .contact {
    &-body {
      display: block;
      padding: 40px $side-gap-half;
    }

    &-form {
      width: 100%;

      &-title {
        font-size: 32px;
      }

      form {
        margin-top: 24px;
      }

      &-button {
        padding: 12px 0;
        width: 35%;
      }
    }

    &-info {
      width: 100%;
    }
  }
}

@media screen and (max-width: $mobile) {
  .contact {
    &-form {
      &-title {
        font-size: 28px;
      }

      &-button {
        width: 50%;
      }
    }
  }
}

@media screen and (min-width: $mega-desktop) {
  .contact {
    &-body {
      margin: 0 auto;
      max-width: $mega-desktop;
    }
  }
}
