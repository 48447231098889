@import '../../colors';
@import '../../fonts';
@import '../../variables';

.landing-hero-copy {
  background: $dark-grey;
  bottom: 0;
  color: $white;
  font-family: $open-sans-bold;
  max-width: 400px;
  padding: 56px 42px 61px;
  position: absolute;
  right: $side-gap;
  z-index: 10;

  hr {
    border-top: solid 7px $white;
    margin: 0;
    margin-bottom: 30px;
    width: 60px;
  }
}

@media screen and (max-width: $desktop) {
  .landing-hero-copy {
    right: $side-gap-half;
  }
}

@media screen and (max-width: $tablet) {
  .landing-hero-copy {
    bottom: 0;
    padding: 46px $side-gap-half 51px;
    position: relative;
    max-width: unset;
    right: 0;
    margin: -190px auto 30px;
    width: 100%;

    h1 {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .landing-hero-copy {
    h1 {
      font-size: 24px;
    }
  }
}

@media screen and (min-width: $mega-desktop) {
  .landing-hero-copy {
    left: 60%;
  }
}