@import '../../colors';
@import '../../fonts';
@import '../../variables';

.specialties-quad {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 60px auto;

  &-section {
    display: flex;
    margin-bottom: 48px;
    width: 45%;

    &-icon {
      align-items: center;
      background: $dark-marine;
      display: flex;
      margin-right: 36px;
      height: 62px;
      min-width: 62px;

      img {
        margin: 0 auto;
      }
    }

    &-title {
      font-family: $open-sans-bold;
      font-size: 20px;
      margin-bottom: 8px;
    }

    &-copy {
      line-height: 2;
    }
  }
}

@media screen and (max-width: $desktop) {
  .specialties-quad {
    &-section {
      width: auto;
    }
  }
}

@media screen and (max-width: $mobile) {
  .specialties-quad {
    margin: 40px auto 20px;

    &-section {
      margin-bottom: 24px;

      &-icon {
        margin-right: 16px;
      }

      &-title {
        font-size: 18px;
      }
    }
  }
}
