@import '../colors';
@import '../fonts';
@import '../variables';

.services {
  background: $sub-page-grey;
  color: $text-grey;
  font-family: $open-sans-reg;
  overflow-x: hidden;

  &-body {
    padding: 60px $side-gap-half;
  }

  &-row {
    display: flex;
    justify-content: space-between;
    margin: 60px $side-gap-half;

    &:first-child {
      margin: 30px $side-gap-half 60px;
    }

    &-side {
      width: 47%;

      img {
        width: 100%;
      }
    }

    &-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      min-height: 380px;
    }

    &-title {
      color: $heading-marine;
      font-size: 36px;
      margin-bottom: 24px;
    }

    &-copy {
      line-height: 2;
    }
  }
}

@media screen and (max-width: $desktop) {
  .services {
    &-body {
      padding: 30px $side-gap-half / 2;
    }

    &-row {
      &-title {
        font-size: 32px;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .services {
    &-row {
      display: block;
      // margin: 40px 3%;

      &-title {
        font-size: 28px;
        margin-bottom: 16px;
        margin-top: 16px;
      }

      &-side {
        width: 100%;
      }

      &-image {
        height: 250px;
        margin-top: 24px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .services {
    &-body {
      padding: 0;
    }

    &-row {
      margin: 30px $side-gap-half;
      
      &-title {
        font-size: 24px;
      }
    }
  }
}
