@import '../colors';
@import '../fonts';
@import '../variables';

.footer {
  background: $sub-page-black;
  color: $light-grey;
  font-family: $open-sans-reg;
  padding: 60px $side-gap;

  &-logo {
    width: 195px;
  }

  &-columns {
    display: flex;
    justify-content: space-between;
  }

  &-address {
    margin-top: 16px;
  }

  &-heading {
    font-family: $open-sans-bold;
    font-size: 18px;
    margin-bottom: 16px;
  }

  &-column {
    width: 20%;

    &:first-child {
      width: 35%;
    }

    &:last-child {
      width: auto;
    }

    a {
      color: $light-grey;
      display: block;
      margin-bottom: 16px;
      text-decoration: none;
    }

    .social {
      a {
        display: inline-block;
        margin-right: 16px;

        img {
          height: 26px;
        }
      }
    }
  }

  &-copyright {
    display: flex;
    justify-content: center;
    margin: 100px $side-gap 30px;

    &-section {
      margin: 0 16px;

      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: $desktop) {
  .footer {
    padding: 60px $side-gap-half;

    &-copyright {
      margin: 60px $side-gap-half 30px;
    }
  }
}

@media screen and (max-width: $tablet) {
  .footer {
    &-columns {
      flex-wrap: wrap;
    }

    &-column {
      width: 30%;

      &:first-child {
        width: 100%;
      }
    }

    &-heading {
      margin-top: 36px;
    }

    &-copyright {
      justify-content: space-between;
      margin: 30px 0 0;

      &-section {
        font-size: 14px;
        margin: 0;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .footer {
    &-logo {
      width: 200px;
    }

    &-columns {
      display: block;
    }

    &-column {
      width: 100%;
    }

    &-copyright {
      display: block;

      &-section {
        text-align: left;
      }
    }
  }
}
