@import '../../colors';
@import '../../fonts';
@import '../../variables';

.contact-info {
  &-block {
    color: $text-grey;
    margin-bottom: 36px;
  }

  &-title {
    font-family: $open-sans-bold;
    font-size: 18px;
    margin-bottom: 8px;
  }

  &-copy {
    font-family: $open-sans-reg;
    font-size: 20px;

    a {
      color: inherit;
    }
  }
}
