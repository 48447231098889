.social-share-button {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;
  padding: 8px 18px;
  text-transform: uppercase;

  img {
    display: inline-block;
    padding-left: 14px;
  }
}

.react-share__ShareButton {
  outline: 0;
}