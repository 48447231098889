@import '../../colors';
@import '../../fonts';
@import '../../variables';

.press-post {
  background: $sub-page-grey;

  &-back {
    // border-bottom: 1px solid;
    color: $orange;
    cursor: pointer;
    display: block;
    font-size: 16px;
    text-decoration: underline;
    text-transform: uppercase;
  }

  &-body {
    font-family: $open-sans-reg;
    padding: 60px $side-gap;
  }

  &-title {
    color: $heading-marine;
    font-size: 36px;
    margin: 16px $side-gap;
  }

  &-content {
    p {
      color: $text-grey;
      margin: 0 $side-gap 16px;
    }

    a {
      border-bottom: 1px solid $orange;
      color: $orange;
      text-decoration: none;

      // &:hover {
      //   border-bottom: 1px solid $orange;
      // }
    }
  }

  &-social {
    display: flex;
    justify-content: space-around;
    margin: 36px auto 0;
    width: 400px;
  }
}

@media screen and (max-width: $desktop) {
  .press-post {
    &-body {
      padding: 60px $side-gap-half 0;
    }

    &-title {
      font-size: 32px;
      margin: 0 $side-gap-half 16px;
    }

    &-content {
      p {
        margin: 0 $side-gap-half 16px;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .press-post {
    &-body {
      padding: 30px 0 0;
    }

    &-title {
      font-size: 28px;
    }
  }
}
