@import '../colors';
@import '../fonts';
@import '../variables';

.landing-press {
  font-family: $open-sans-reg;
  padding: 60px $side-gap 0;

  &-heading {
    background: $marine;
    color: $white;
    font-family: $open-sans-bold;
    font-size: 24px;
    padding: 20px;
    width: 25%;
  }

  &-main {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;

    &-left {
      padding: 0 24px;
      width: 25%;

      a {
        color: inherit;
      }

      .inquiries {
        color: $marine;
        font-family: $open-sans-reg;
        font-size: 28px;
        padding-bottom: 16px;
      }

      .phone {
        color: $light-grey;
        font-family: $open-sans-bold;
        font-size: 22px;
        margin-bottom: 2px;
      }

      .email {
        color: $orange;
        font-size: 18px;
      }
    }

    &-right {
      width: 75%;
    }
  }
}

@media screen and (max-width: $desktop) {
  .landing-press {
    padding: 60px $side-gap-half 0;

    &-heading {
      width: 30%;
    }

    &-main {
      padding: 30px 0;

      &-left {
        padding-left: 0;
        width: 30%;

        .inquiries {
          font-size: 24px;
        }

        .phone {
          font-size: 20px;
        }
      }

      &-right {
        width: 70%;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .landing-press {
    &-heading {
      width: 100%;
    }

    &-main {
      display: block;

      &-left {
        padding-bottom: 24px;
        width: 100%;
      }

      &-right {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .landing-press {
    padding: 30px $side-gap-half 0;

    &-heading {
      font-size: 22px;
    }

    &-main {
      padding: 20px 0;

      &-left {
        .inquireies {
          font-size: 20px;
          padding-bottom: 8px;
        }
      }
    }
  }
}
