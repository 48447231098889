@import '../../colors';
@import '../../fonts';
@import '../../variables';

.landing-sub-hero {
  color: $light-grey;
  margin: 60px auto 100px;
  text-align: center;
  width: 64%;

  &-top {
    font-family: $open-sans-bold;
    font-size: 28px;
  }

  &-bottom {
    font-family: $open-sans-reg;
    font-size: 32px;
  }
}

@media screen and (max-width: $tablet) {
  .landing-sub-hero {
    margin: 40px auto 60px;
    width: 80%;

    &-top {
      font-size: 26px;
    }

    &-bottom {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .landing-sub-hero {
    &-top {
      font-size: 24px;
    }

    &-bottom {
      font-size: 26px;
    }
  }
}

@media screen and (max-width: $small-mobile) {
  .landing-sub-hero {
    margin: 30px auto 40px;
    width: 90%;

    &-top {
      font-size: 22px;
    }

    &-bottom {
      font-size: 20px;
    }
  }
}
