@import '../colors';
@import '../fonts';
@import '../variables';

.support {
  background: $sub-page-grey;
  font-family: $open-sans-reg;

  &-body {
    padding: 60px 15% 40px;
  }

  &-heading {
    color: $heading-marine;
    font-size: 36px;
    margin-bottom: 16px;
  }
}