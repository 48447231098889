@import '../colors';
@import '../fonts';
@import '../variables';

.mini-nav {
  background: $white;
  color: $light-grey;
  font-family: $open-sans-bold;
  max-height: 265px;
  padding: 36px;
  width: 30%;

  &-link {
    color: $light-grey;
    display: block;
    font-size: 18px;
    margin-bottom: 16px;
    text-transform: uppercase;

    &.active {
      color: $orange;
    }
  }
}

.specialties {
  background: $sub-page-grey;
  font-family: $open-sans-reg;
  overflow-x: hidden;

  &-body {
    display: flex;
    justify-content: space-between;
    padding: 60px $side-gap;
  }

  &-page {
    color: $text-grey;
    margin-left: 36px;
    width: 70%;

    &-header {
      color: $heading-marine;
      font-size: 36px;
      margin-bottom: 24px;

      &-below {
        line-height: 2;
      }
    }

    &-image {
      margin-bottom: 60px;
      width: 100%;
    }
  }
}

@media screen and (max-width: $desktop) {
  .specialties {
    &-body {
      padding: 60px $side-gap-half;
    }

    &-page {
      width: 60%;

      &-header {
        font-size: 32px;
      }
    }
  }

  .mini-nav {
    max-height: 320px;
    padding: 24px;
    width: 35%;
  }
}

@media screen and (max-width: $tablet) {
  .specialties {
    &-body {
      display: block;
      padding: 30px 3%;
    }

    &-page {
      margin-left: 0;
      margin-top: 24px;
      width: 100%;

      &-header {
        font-size: 28px;
      }
    }

    .mini-nav {
      background: $transparent;
      border-bottom: 1px solid;
      display: flex;
      justify-content: space-between;
      max-height: unset;
      padding: 0;
      width: 100%;

      &-link {
        border-bottom: 1px solid transparent;
        display: inline-block;
        font-size: 14px;
        margin-bottom: 0;
        padding-bottom: 16px;
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .specialties {
    &-page {
      &-header {
        font-size: 24px;
      }
    }

    .mini-nav {
      display: block;

      &-link {
        display: block;
        padding-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .specialties {
    &-page {
      &-header {
        font-size: 22px;
      }

      &-image {
        margin-bottom: 0;
      }
    }
  }
}
