@import '../colors';
@import '../fonts';
@import '../variables';

.sub-page-hero {
  font-family: $open-sans-reg;

  &-image {
    background: $sub-page-black;
    background-position: center;
    background-size: cover;
    display: flex;
    height: 400px;
    justify-content: flex-end;

    &.thin {
      height: 250px;
    }
  }

  &-title {
    color: $white;
    font-family: $open-sans-bold;
    font-size: 32px;
    text-transform: uppercase;

    &-wrapper {
      display: flex;
      justify-content: flex-end;
      margin: 34px $side-gap;
    }
  }

  &-copy {
    background: $sub-page-black;
    color: $white;
    font-size: 20px;
    margin-top: -150px;
    padding: 40px $side-gap;
    width: 60%;

    &-inner {
      line-height: 2;
      max-width: 720px;
      margin-left: auto;
    }
  }
}

@media screen and (max-width: $desktop) {
  .sub-page-hero {
    &-title-wrapper {
      margin: 34px $side-gap-half;
    }

    &-copy {
      font-size: 18px;
      padding: 40px $side-gap-half;
    }
  }
}

@media screen and (max-width: $tablet) {
  .sub-page-hero {
    &-image {
      height: 300px;

      &.thin {
        height: 200px;
      }
    }

    &-copy {
      font-size: 16px;
      margin-top: -170px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .sub-page-hero {
    &-image {
      height: 150px;
    }

    &-title {
      font-size: 26px;
    }

    &-copy {
      margin-top: 0;
      padding: 30px $side-gap-half;
      position: relative;
      width: 100%;
    }
  }
}

@media screen and (min-width: $mega-desktop) {
  .sub-page-hero {
    &-title {
      &-wrapper {
        margin: 34px auto;
        width: $mega-desktop;
      }
    }
  }
}
