@import '../../colors';
@import '../../fonts';
@import '../../variables';

.contact-offices {
  background: $white;
  margin: 60px $side-gap;

  &-heading {
    color: $heading-marine;
    font-family: $open-sans-reg;
    font-size: 36px;
    margin-bottom: 36px;
  }

  &-row {
    display: flex;
    justify-content: space-between;
  }

  &-image {
    background-size: cover;
    height: 250px;
    width: 100%;
  }

  &-title {
    color: $text-grey;
    font-family: $open-sans-bold;
    font-size: 20px;
    margin: 16px 0;
  }

  &-address {
    color: $text-grey;
    font-family: $open-sans-reg;
    font-size: 18px;
    line-height: 1.6;
  }

  &-dallas {
    margin-right: 16px;
    width: 50%;
  }

  &-halfs {
    display: flex;
    justify-content: space-between;
    width: 50%;
  }

  &-half {
    margin-right: 16px;
    width: 50%;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: $desktop) {
  .contact-offices {
    margin: 60px $side-gap-half;

    &-dallas {
      margin-right: 16px;
      width: 40%;
    }

    &-halfs {
      margin-right: 16px;
      width: 60%;
    }
  }
}

@media screen and (max-width: $tablet) {
  .contact-offices {
    margin: 40px $side-gap-half;

    &-heading {
      font-size: 32px;
      margin-bottom: 16px;
    }

    &-row {
      flex-wrap: wrap;
    }

    &-dallas {
      margin-bottom: 16px;
      margin-right: 0;
      width: 100%;
    }

    &-halfs {
      margin-right: 0;
      width: 100%;
    }
  }
}

@media screen and (max-width: $mobile) {
  .contact-offices {
    &-heading {
      font-size: 28px;
    }

    &-halfs {
      display: block;
    }

    &-half {
      margin-bottom: 16px;
      margin-right: 0;
      width: 100%;
    }
  }
}
