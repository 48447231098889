@import '../colors';
@import '../fonts';
@import '../variables';

.csh {
  background: $footer-bg;
  color: $white;

  &-inner {
    align-items: center;
    display: flex;
    margin: auto;
    // width: 80%;

    &-left {
      align-items: center;
      display: flex;
      padding: 1em 0;
      width: 80%;

      a {
        display: block;
        margin-right: 1em;

        img {
          display: block;
          width: 120px;
        }
      }
    }

    &-right {
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      text-align: right;
      width: 20%;

      .social {
        align-items: flex-end;
        display: flex;
        justify-content: flex-end;
        text-align: right;
        width: 100%;

        a {
          display: block;
          justify-content: flex-end;
          margin-left: 1em;
          text-align: right;

          img {
            display: block;
            text-align: right;
            width: 24px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1520px) {
  .csh {
    &-inner {
      width: 80%;
    }
  }
}

@media screen and (max-width: 800px) {
  .csh {
    display: none;
    // &-inner {
    //   width: 80%;
    // }
  }
}