@import '../colors';
@import '../fonts';
@import '../variables';

.expertise {
  background: $sub-page-grey;
  font-family: $open-sans-reg;

  &-heading {
    color: $heading-marine;
    font-size: 36px;
    margin-bottom: 16px;
  }

  &-copy {
    color: $text-grey;
    line-height: 2;
    margin-bottom: 60px;
  }

  &-mti {
    margin: 60px 15% 40px;

    &-body {
      color: $text-grey;

      p {
        line-height: 2;
        margin-bottom: 8px;
      }

      ul {
        list-style-type: none;
        margin-bottom: 16px;

        li {
          &::before {
            content: '\2014';
            margin-left: -20px;
            position: absolute;
          }
        }
      }
    }
  }

  &-construction {
    display: block;
    margin: 60px auto;
    width: 90%;
  }

  &-content-block {
    margin: 60px 15% 40px;
  }
}

@media screen and (max-width: $desktop) {
  .expertise {
    &-heading {
      font-size: 32px;
    }

    &-mti {
      margin: 60px 7% 40px;
    }

    &-content-block {
      margin: 60px 7% 40px;
    }
  }
}

@media screen and (max-width: $tablet) {
  .expertise {
    &-heading {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .expertise {
    &-heading {
      font-size: 24px;
    }

    &-mti {
      margin: 40px 7%;
    }

    &-construction {
      margin: 40px auto;
    }

    &-content-block {
      margin: 20px 7%;
    }
  }
}

