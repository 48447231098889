@import '../colors';
@import '../fonts';
@import '../variables';

.press-excerpt {
  border-bottom: 1px solid $press-border;
  margin-bottom: 24px;

  &:last-child {
    border: 0;
  }

  &-date {
    color: $light-grey;
    font-weight: 600;
    margin-bottom: 4px;
  }

  &-title {
    color: $marine;
    font-size: 26px;
    margin-bottom: 8px;
  }

  &-preview {
    color: $marine;
    margin-bottom: 24px;
  }

  &-read-more {
    align-items: center;
    color: $light-grey;
    display: flex;
    font-family: $open-sans-bold;
    margin-bottom: 24px;
    text-decoration: none;
    text-transform: uppercase;

    &-text {
      border-bottom: 1px solid $transparent;
      margin-right: 12px;
      margin-top: 1px;

      &:hover {
        border-bottom: 1px solid;
        color: $orange;
      }
    }

    &-arrow {
      height: 12px;
    }

    img {
      display: block;
      height: 12px;
    }
  }
}

@media screen and (max-width: $tablet) {
  .press-excerpt {
    &-title {
      font-size: 22px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .press-excerpt {
    &-title {
      font-size: 20px;
    }
  }
}

