@import '../../colors';
@import '../../fonts';
@import '../../variables';

.growth {
  background: $white;
  display: flex;
  justify-content: space-around;
  padding: 80px $side-gap 100px 0;
  width: 100%;

  &-wrapper {
    background: $white;
    width: 100%;
    overflow-x: hidden;
  }

  &-heading {
    color: $growth-grey;
    font-family: $open-sans-bold;
    font-size: 160px;
    font-weight: 900;
    transform: rotate(-90deg) translate(-165px, 250px);
    width: 20%;
  }

  &-body {
    width: 80%;
  }

  &-title {
    color: $marine;

    p {
      color: $text-grey;
      font-family: $open-sans-bold;
    }

    h2 {
      color: $marine;
      font-family: $open-sans-reg;
      font-size: 34px;
      font-weight: normal;
      text-align: center;
    }
  }

  &-chart {
    margin: 35px auto 35px 0;
    width: 75%;
  }

  &-item {
    margin-bottom: 30px;

    p {
      font-size: 16px;
      margin-bottom: 10px;
    }

    span {
      color: $marine;
      float: right;
      font-size: 19px;
      font-weight: 600;
    }

    .progress {
      background-color: $chart-grey;
      height: 5px;

      &-bar {
        background-color: $orange;
        height: 5px;
      }
    }
  }

  &-stats-container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
}

@media screen and (max-width: $desktop) {
  .growth {
    padding: 80px $side-gap-half 100px 0;

    &-heading {
      font-size: 150px;
      transform: rotate(-90deg) translate(-125px, 200px);
      width: 25%;
    }

    &-body {
      margin-right: $side-gap-half;
    }

    &-title {
      h2 {
        font-size: 32px;
      }
    }

    &-chart {
      width: 100%;
    }
  }
}

@media screen and (max-width: $tablet) {
  .growth {
    display: block;
    padding: 30px 5% 100px;

    &-heading {
      font-size: 80px;
      transform: rotate(0deg);
      text-align: center;
      width: auto;
    }

    &-body {
      margin-right: 0;
      width: 100%;
    }

    &-title {
      h2 {
        font-size: 28px;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .growth {
    display: block;
    padding: 0 5% 40px;

    &-heading {
      transform: rotate(0deg);
      width: 100%;
    }

    &-body {
      width: 100%;
    }

    &-title {
      h2 {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .growth {
    &-title {
      h2 {
        font-size: 20px;
      }
    }

    &-chart {
      margin: 15px auto 35px 0;
    }
  }
}

@media screen and (max-width: $small-mobile) {
  .growth {
    &-heading {
      font-size: 70px;
    }

    &-item {
      p {
        font-size: 14px;
      }

      span {
        font-size: 16px;
      }
    }
  }
}

@media screen and (min-width: $mega-desktop) {
  .growth {
    margin: 0 auto;
    max-width: $mega-desktop;
    padding-right: $side-gap-half;
  }
}