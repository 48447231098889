@font-face {
  font-family: 'OpenSans-Regular';
  src: url('fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('fonts/OpenSans-Bold.ttf') format('truetype');
}

$open-sans-reg: 'OpenSans-Regular';
$open-sans-bold: 'OpenSans-Bold';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&display=swap');