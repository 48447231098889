@import '../../colors';
@import '../../fonts';
@import '../../variables';

.landing-careers {
  color: $text-grey;
  display: flex;
  font-family: $open-sans-reg;
  justify-content: space-between;
  margin: 60px $side-gap 30px;
  max-width: $mega-desktop;

  &-left {
    margin-right: 36px;
    text-align: right;
    width: 25%;

    img {
      // display: block;
      width: 65%;
    }
  }

  &-right {
    width: 75%;
  }

  &-heading {
    font-size: 32px;
    margin-bottom: 16px;
  }

  &-copy {
    font-size: 18px;
    line-height: 1.8;
  }
}

@media screen and (max-width: $desktop) {
  .landing-careers {
    margin: 60px $side-gap-half;
  }
}

@media screen and (max-width: $tablet) {
  .landing-careers {
    display: block;
    margin: 0 $side-gap-half;

    &-left {
      margin: 0 auto 36px;
      text-align: center;
      width: 50%;

      img {
        width: 75%;
      }
    }

    &-right {
      margin-bottom: 36px;
      width: 100%;
    }

    &-heading {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .landing-careers {
    &-heading {
      font-size: 22px;
    }

    &-copy {
      font-size: 16px;
    }
  }
}

@media screen and (min-width: $mega-desktop) {
  .landing-careers {
    margin: 60px auto 0;
  }
}
