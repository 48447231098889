@import '../../colors';
@import '../../fonts';
@import '../../variables';

.case-studies {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &-card {
    background: $dark-marine;
    // border: 1px solid white;
    color: $white;
    display: flex;
    flex-direction: column;
    font-family: $open-sans-reg;
    justify-content: space-between;
    padding: 3em 6em;
    position: relative;
    text-align: center;

    &-image {
      filter: grayscale(.35);
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform-origin: center center;
      width: 100%;
      z-index: 1;

      &-overlay {
        background: $black;
        height: 100%;
        left: 0;
        opacity: 0.35; 
        position: absolute;
        top: 0;
        transform-origin: center center;
        width: 100%;
        z-index: 2;
      }
    }

    &-title {
      font-family: $open-sans-bold;
      font-size: 2em;
      margin-bottom: 1em;
      text-transform: uppercase;
      z-index: 3;
    }

    &-copy {
      font-size: 1.2em;
      margin: 0 auto;
      max-width: 480px;
      width: 85%;
      z-index: 3;
    }

    &-button {
      backdrop-filter: blur(3px);
      border: 1px solid $white;
      color: $white;
      cursor: pointer;
      font-family: $open-sans-bold;
      font-size: 1.4em;
      margin: 2em auto 0;
      padding: 6px;
      text-transform: uppercase;
      width: 230px;
      z-index: 3;
    }
  }
}

@media screen and (max-width: 600px) {
  .case-studies {
    grid-template-columns: 1fr;

    &-card {
      padding: 2em 1em;

      &-title {
        font-size: 1.6em;
      }

      &-copy {
        font-size: 1em;
        width: 95%;
      }

      &-button {
        font-size: 1.2em;
      }
    }
  }
}
