@import '../../colors';
@import '../../fonts';
@import '../../variables';

.donut {
  align-items: center;
  display: flex;
  overflow: hidden;
  position: relative;

  &-hole {
    font-family: 'Open Sans';
    pointer-events: none;
    position: absolute;
    top: 33%;
    width: 100%;
    text-align: center;

    &-wrapper {
      position: relative;
      width: 100%;
    }

    &-large {
      color: $dark-marine;
      font-size: 48px;
      font-weight: 100;
      margin-right: -6px;
    }

    &-small {
      color: $light-marine;
      font-size: 18px;
    }
  }

  &-hovered {
    color: $orange;
    font-size: 22px;
    font-weight: 700;
  }

  &-legend {
    position: absolute;
    right: 0;

    &-row {
      align-items: center;
      cursor: crosshair;
      display: flex;
      margin-bottom: 14px;
      transition: .55s ease;

      &.opacify {
        opacity: .35;
      }
    }

    &-label {
      color: $dark-marine;
      font-size: 15px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &-key {
      margin-right: 22px;
    }

    &-dot {
      padding: 10px;
      border-radius: 100%;
      margin-right: 12px;
    }
  }
}

@media screen and (max-width: 1250px) {
  .donut {
    &-hole {
      // top: 28%;

      &-large {
        font-size: 36px;
      }

      &-small {
        margin: 0 auto;
        width: 190px;
      }

      &-hovered {
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: $desktop) {
  .donut {
    &-hole {
      &-large {
        font-size: 32px;
      }

      &-small {
        font-size: 16px;
      }

      &-hovered {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .donut {
    display: block;

    &-legend {
      pointer-events: none;
      position: relative;
      left: 0;
    }

    &-hole {
      top: 30%;
    }

    &-hovered {
      font-size: 16px;
      width: 140px;
      margin: 0 auto;
    }
  }
}

@media screen and (max-width: 550px) {
  .donut {
    overflow: visible;

    &-legend {
      &-row {
        margin-bottom: 8px;
      }

      &-dot {
        padding: 7px;
      }

      &-label {
        font-size: 14px;
      }
    }

    &-hole {
      position: relative;
      margin: 16px auto;

      &-small {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .donut {
    display: block;

    &-hole {
      margin: 16px auto;
      position: relative;

      &-small {
        width: 100%;
      }
    }

    &-legend {
      position: relative;
    }
  }
}