@import '../../colors';
@import '../../fonts';
@import '../../variables';

.landing-mti {
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: $open-sans-reg;
  padding: 60px $side-gap;

  &-box {
    background: $mti-grey;
    color: $text-grey;
    padding: 46px 64px 64px;
    width: 50%;

    &-title {
      font-family: $open-sans-bold;
      font-size: 28px;
      margin-bottom: 18px;
    }
  }
}

@media screen and (max-width: $desktop) {
  .landing-mti {
    padding: 60px $side-gap-half;
  }
}

@media screen and (max-width: $tablet) {
  .landing-mti {
    padding: 120px $side-gap-half 30px;

    &-box {
      margin-top: 42px;
      padding: 36px 46px 54px;
      width: 100%;
    }
  }
}

@media screen and (max-width: $mobile) {
  .landing-mti {
    &-box {
      margin-top: 82px;

      &-title {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: $small-mobile) {
  .landing-mti {
    &-box {
      padding: 32px 28px 44px;

      &-title {
        font-size: 22px;
        margin-bottom: 12px;
      }
    }
  }
}

// @media screen and (min-width: $mega-desktop) {
//   .landing-mti {
//     background-position: right top;
//   }
// }
