.logo-loading {
  align-items: center;
  backdrop-filter: saturate(140%) blur(5px);
  background: rgba(0, 0, 0, .2);
  bottom: 0;
  display: flex;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;

  img {
    margin: 0 auto;
  }
}

// body {
//   overflow: hidden;
// }
