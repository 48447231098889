@import '../colors';
@import '../fonts';
@import '../variables';

.nav {
  &-bar {
    align-items: center;
    background: $white;
    border-bottom: 1px solid $off-white;
    display: flex;
    justify-content: space-between;
    padding: 22px $side-gap;
  }

  &-logo {
    img {
      width: 195px;
    }
  }

  &-link {
    color: $dark-grey;
    font-family: $open-sans-bold;
    font-size: 15px;
    margin: 0 12px;
    text-decoration: none;

    &:hover,
    &.active {
      color: $orange;
    }
  }
}

button {
  &.menu-toggle {
    background: transparent;
    border: 0;
    // border-radius: 50%;
    cursor: pointer;
    height: 24px;
    outline: none;
    padding: 0;
    position: absolute;
    right: $side-gap-half;
    top: 28px;
    user-select: none;
    // width: 34px;
    z-index: 69;
  }
}

.mobile-nav-top {
  height: 80px;

  &.with-border {
    border-bottom: 1px solid $light-grey;
  }
}

.mobile-nav-logo {
  left: 5%;
  position: absolute;
  top: 18px;
  width: 160px;
}

.mobile-nav-background {
  background: $marine;
  bottom: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 66;

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  ul {
    margin: 15% auto;
    width: 225px;
  }

  li {
    align-items: center;
    cursor: pointer;
    display: block;
    font-family: $open-sans-reg;
    font-size: 22px;
    list-style: none;
    padding: .5rem 1rem;

    .social {
      display: flex;
      justify-content: space-around;
      margin: 70% auto;

      a {
        display: block;
        width: 100%;

        img {
          display: block;
          margin: 0 auto;
          width: 36px;
        }
      }
    }
  }
}

a {
  &.mobile-nav-link {
    color: $off-white;
  }
}

// .headroom {
//   height: 95px;
// }

@media screen and (max-width: $desktop) {
  .nav {
    &-bar {
      padding: 18px $side-gap-half 16px;
    }

    &-logo {
      img {
        width: 170px;
      }
    }
  }
}

@media screen and (max-width: $mobile) {
  .nav {
    &-bar {
      padding: 12px $side-gap-half 10px;
    }

    &-logo {
      img {
        width: 140px;
      }
    }
  }

  .mobile-nav {
    &-background {
      ul {
        margin: 0 auto;
        max-width: 100%;
        padding: $side-gap-half;
        width: 100%;
      }
    }

    &-logo {
      top: 13px;
    }

    &-top {
      height: 62px;

      .nav-logo {
        display: inline-block;
      }
    }
  }

  button {
    &.menu-toggle {
      top: 19px;
    }
  }
}

@media screen and (max-width: $small-mobile) {
  .nav {
    &-bar {
      padding: 12px $side-gap 10px;
    }

    &-logo {
      img {
        width: 120px;
      }
    }
  }
}

@media screen and (min-width: $mega-desktop) {
  .nav {
    &-bar {
      padding: 22px 0;
    }

    &-link {
      font-size: 16px;
    }
  }
}
