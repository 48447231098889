@import 'colors';
@import 'fonts';

body {
  margin: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  // border: 1px solid red;

  &::selection {
    background: $faded-orange;
  }
}

a {
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

.simplebar-scrollbar {
  &::before {
    background-color: $orange;
  }
}

.app {
  background: $white;
}

// .scroll-progress {
//   position: fixed;
//   background-color: $orange;
//   height: 5px;
//   top: 0;
//   left: 0;
//   width: 100%;
//   z-index: 9999;
// }



.motion-hero img {
  position:absolute;
  top:0;
  left:0;
}
