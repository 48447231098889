@import '../../colors';
@import '../../fonts';
@import '../../variables';

.landing-button {
  border: 1px solid;
  color: $light-grey;
  font-family: $open-sans-bold;
  margin-bottom: 60px;
  padding: 16px;
  text-align: center;

  &-link {
    display: block;
    width: 20%;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &:hover {
    background: $light-grey;
    border: 1px solid $light-grey;
    color: $white;
  }

  &-row {
    display: flex;
    justify-content: flex-end;
    margin: 0 $side-gap 60px;
  }
}

@media screen and (max-width: $desktop) {
  .landing-button {
    &-row {
      margin: 0 $side-gap-half 60px;
    }
  }
}

@media screen and (max-width: $tablet) {
  .landing-button {
    padding: 12px;

    &-link {
      width: 35%;
    }

    &-row {
      margin: 0 $side-gap-half;
    }
  }
}

@media screen and (max-width: $mobile) {
  .landing-button-link {
    width: 50%;
  }
}

@media screen and (max-width: $small-mobile) {
  .landing-button-link {
    width: 65%;
  }
}

@media screen and (min-width: $mega-desktop) {
  .landing-button {
    &-row {
      margin: 0 0 60px;
    }
  }
}
