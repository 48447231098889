@import '../colors';
@import '../fonts';
@import '../variables';

.privacy {
  background: $sub-page-grey;
  font-family: $open-sans-reg;
  padding: 22px $side-gap;

  h2,
  h3 {
    margin-bottom: 18px;
  }

  p,
  ul {
    font-size: 14px;
    margin-bottom: 16px;
  }

  li {
    margin-bottom: 12px;
  }
}