@import '../../colors';
@import '../../fonts';
@import '../../variables';

.growth-stats-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  position: relative;
  z-index: 20;

  .col {
    color: $light-grey;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    width: 100%;
  }

  .stats {
    font-size: 50px;

    &-symbol {
      font-size: 40px;
    }
  }

  img {
    display: block;
    margin: 15px auto 30px;
    width: 36px;
  }
}

@media screen and (max-width: $desktop) {
  .growth-stats-container {
    .stats {
      font-size: 42px;

      &-symbol {
        font-size: 32px;
      }
    }
  }
}

@media screen and (max-width: $tablet) {
  .growth-stats-container {
    flex-wrap: wrap;
    justify-content: space-evenly;

    .col {
      width: 30%;
    }

    .stats {
      font-size: 34px;
    }

    .stats-symbol {
      font-size: 28px;
    }
  }
}

@media screen and (max-width: $mobile) {
  .growth-stats-container {
    .stats {
      font-size: 30px;
    }

    .stats-symbol {
      font-size: 24px;
    }

    img {
      width: 28px;
    }
  }
}

@media screen and (max-width: $small-mobile) {
  .growth-stats-container {
    .col {
      width: 45%;

      .copy {
        font-size: 15px;
      }
    }

    .stats {
      font-size: 26px;
    }

    .stats-symbol {
      font-size: 22px;
    }
  }
}
